import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
@Injectable()
export abstract class Unsub implements OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
